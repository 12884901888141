import React from "react";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const Form = (props) => {
  const {
    emailValue,
    isEmpty,
    disabled,
    onEmailChange,
    onError,
    onKey,
    onSubmit,
  } = props;
  return (
    <>
      <form
        className={getTrueClasses(
          "PgEM-HI-FI-F",
          !isEmpty && !onError && "PgEM-HI-FI-F_typing",
          onError && "PgEM-HI-FI-F_alert"
        )}
      >
        <input
          className="PgEM-HI-FI-F__input"
          type="text"
          placeholder="Enter your email here"
          value={emailValue}
          onChange={onEmailChange}
          onKeyDown={onKey}
        />
        <button
          type="button"
          className="PgEM-HI-FI-F__button"
          onClick={onSubmit}
          disabled={disabled}
        >
          Start Your Free Trial
        </button>
      </form>
      {onError && <label className="PgEM-HI-FI-F__label">{onError}</label>}
    </>
  );
};

export default Form;
