import React from "react";
import SVG_POINTER from "../../../../assets/images/vector/pages/main/HomeIntro/pointer.svg";
import "./index.scss";

const POINTS_TEXT = [
  "Find profitable products, the best keywords, and proven suppliers",
  "Let AI manage the whole process, from improving a product idea to creating a listing",
  "Achieve financial freedom and independence so you can quit your job with confidence",
];

const Description = () => (
  <div className="PgEM-HI-D">
    <h2 className="PgEM-HI-D__title">Start and Grow Your Amazon Business</h2>
    <ul className="PgEM-HI-D-points">
      {POINTS_TEXT.map((text, i) => (
        <li key={i} className="PgEM-HI-D-points__item">
          <img
            className="PgEM-HI-D-points__pointer"
            src={SVG_POINTER}
            alt="-"
          />
          <p className="PgEM-HI-D-points__text">{text}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default Description;
