import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import useCheckLicense from "../../../hooks/useCheckLicense";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import Container from "../../common/UI/Container";
import Description from "./Description";
import Offer from "./Offer";
import Screen from "./Screen";
import FormContainer from "../../../containers/common/forms/FormContainer";
import FormIntro from "./FormIntro";
import "./index.scss";

const HomeIntro = ({ homeIntroRef }) => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const isPaid = useCheckLicense();
  const option = {
    redirectButton: {
      category: "main_page",
      buttonText: isPaid
        ? "Go to your AMZScout account"
        : "Start Your Free Trial",
    },
  };
  const status = isAuth ? (isPaid ? "paid" : "trial") : "unknown";

  return (
    <section
      ref={homeIntroRef}
      className={getTrueClasses("PgEM-HI", isAuth && "PgEM-HI_auth")}
      id="home"
    >
      <Container mode="standard">
        <div className="PgEM-HI-content">
          <Description />
          <div className="PgEM-HI-form">
            <FormContainer page={PAGES.MAIN_PAGE}>
              <FormIntro option={option} />
            </FormContainer>
          </div>
          <Screen />
          <Offer status={status} />
        </div>
      </Container>
    </section>
  );
};

HomeIntro.propTypes = {
  pageAuthState: PropTypes.bool,
  setIsSecondStateForm: PropTypes.func,
  setIsPaid: PropTypes.func,
  isPaid: PropTypes.bool,
};

export default HomeIntro;
