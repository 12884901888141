import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const TITLES = {
  unknown: "Sign up for free and get:",
  trial: "Get for free now:",
  paid: "Your full access to:",
};

const ITEMS_LIST = [
  "Unlimited-Time Seller’s Toolset Trial",
  "Hot Products and Amazon Trend Reports",
  `Step-by-Step “How to Sell on Amazon” Course`,
];

const Offer = ({ status }) => (
  <div className={getTrueClasses("PgEM-HI-O", `PgEM-HI-O_${status}`)}>
    <p className="PgEM-HI-O__title">{TITLES[status]}</p>
    {ITEMS_LIST.map((text, i) => (
      <div key={i} className={`PgEM-HI-O-item PgEM-HI-O-item_i${i + 1}`}>
        <div className="PgEM-HI-O__num">
          <span>{i + 1}</span>
        </div>
        <p className="PgEM-HI-O__text">{text}</p>
      </div>
    ))}
  </div>
);

Offer.propTypes = {
  status: PropTypes.string,
};

export default Offer;
